<template>
  <div class="VideoChatPublicPage">
    <GradientBackLayout
      class="Activity"
      :title="$t('activity_type_wat_videochat')"
      :shadowText="$t('access')"
      v-show="showLogin"
      >
      <BoxShadow class="VideoChatPublicPage--Box">
        <div class="VideoChatPublicPage--Form">
            <label>{{$t('videochat_public_test_user_name')}}</label>
            <a-tooltip>
                <template #title>{{$t('nickname_rgpd_warning')}}</template>
                <CommunityInput type="text" :required="true" :placeholder="$t('role_user')" v-model="nickname"/>
            </a-tooltip>
          <label>{{$t('videochat_public_password')}}</label>
          <CommunityInput type="password" :required="true" :placeholder="$t('password')" v-model="password"/>
          <div class="VideoChatPublicPage--Acceptance" v-if="!isObserverRoute">
            <div>
              <input type="checkbox" name="ch-acceptance" v-model="participationChecked">
              <label for="ch-acceptance">{{this.acceptance}}</label>
            </div>
            <div>
              <input type="checkbox" name="ch-conditions" v-model="conditionsChecked">
              <label for="ch-conditions">  {{$t('signup_conditions1')}}
                <router-link :to="{ name: 'conditions' }" target="_blank">{{$t('signup_conditions2')}}</router-link>
              </label>
            </div>
          </div>
          <CommunityButton :disabled="disableSubmitButton()" v-on:click="validatePassword()">Continuar</CommunityButton>
          <p v-show="error"> {{ errorMessage }} </p>
        </div>
      </BoxShadow>
    </GradientBackLayout>
    <VideoChatPage v-if="showVideoChat"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import { mapGetters, mapMutations  } from 'vuex';
  import GradientBackLayout from '@/layouts/GradientBackLayout.vue'
  import BoxShadow from '@/components/BoxShadow.vue'
  import CommunityButton from '@/components/CommunityButton.vue'
  import CommunityInput from '@/components/CommunityInput.vue';
  import { COMMUNITY_VALIDATE_VIDEO_CHAT_PASSWORD, COMMUNITY_VIDEOCHAT_ADD_USER_PUBLIC, COMMUNITY_VIDEOCHAT_ADD_OBSERVER_PUBLIC } from '@/store/community/chat.module';
  import { USER_CLIENT_FORCE_AUTH_LOGIN, USER_FETCH_PROFILE, USER_AUTH_SIGNUP, USER_GET_NICKNAME_AVAILABILITY } from '@/store/user.module'
  import {COMMUNITY_FETCH_ONLINE_USERS, COMMUNITY_FETCH_MEMBERS} from '@/store/community/members.module';
  import { COMMUNITY_FETCH_CONDITIONS } from '@/store/community/info.module';
  import {
    ROLE_USER,
    ROLE_OBSERVATOR,
  } from '~/constants/user-roles.type';
  import VideoChatPage from '@/views/VideoChatPage'
  import store from '@/store';
  export default {
    name: 'VideoChatPublicPage',

    components: {
      GradientBackLayout,
      CommunityButton,
      CommunityInput,
      BoxShadow,
      VideoChatPage
    },

    props:{
      isObserverRoute:{
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapGetters([
        'theme',
        'communityInfo',
        'communityCurrentActivity',
        'communityCurrentActivityId',
        'communityChatMessagesObs',
        'isAuthenticated',
        'isClient',
        'isUser',
        'isObservator',
        'isModerator',
        'userProfile',
        'onlineUsers',
      ]),
    },

    data() {
      return {
        password:'',
        nickname:'',
        error:false,
        errorMessage: '',
        authToken:null,
        showVideoChat:false,
        showLogin:false,
        acceptance:"",
        participationChecked:false,
        conditionsChecked:false
      }
    },

    methods: {
      disableSubmitButton(){
        let disable = true
        if(this.password.length > 0 && this.nickname.length > 2){
          if(this.isObserverRoute) disable = false
          else if (this.participationChecked && this.conditionsChecked) disable = false
        }
        return disable
      },
      async validatePassword(){
        this.error = false
        await store.dispatch(COMMUNITY_VALIDATE_VIDEO_CHAT_PASSWORD,{password:this.password,id:this.$route.params.id})
        .then((data) => {
          if(this.isObserverRoute) this.createObserver()
          else this.beforeCreateUser()
        })
        .catch((response) => {
          this.errorMessage = this.$t('videochat_public_password_error')
          this.error = true
        })
      },
      async loginObserver(){
        await store.dispatch(USER_CLIENT_FORCE_AUTH_LOGIN,{authToken:this.authToken,role:ROLE_OBSERVATOR, mainAdmin:false,})
        await store.dispatch(USER_FETCH_PROFILE)
        .then((data) => {
          this.showLogin = false
          this.showVideoChat = true
        })
        await store.dispatch(COMMUNITY_FETCH_MEMBERS,{order:'recent'})
        setInterval( (  ) => {
          this.fetchOnlineUsers()
        }, 10000 );
      },
      async fetchOnlineUsers(){
        await store.dispatch(COMMUNITY_FETCH_ONLINE_USERS)
      },
      async createObserver(){
        const observer = {
          name: this.nickname,
          password: this.password
        }
        await store.dispatch(COMMUNITY_VIDEOCHAT_ADD_OBSERVER_PUBLIC,{data: observer,activityId:this.$route.params.id})
        .then((data) => {
          this.authToken = data.authToken
          this.loginObserver()
        })
      },
      async beforeCreateUser(){
        Swal.fire({
          title: this.$t('videochat_public_confirm_nickname_title',{name:this.nickname}),
          html: this.$t('videochat_public_confirm_nickname_text'),
          showCancelButton: true,
          cancelButtonText: this.$t('videochat_public_confirm_nickname_cancel_button'),
          confirmButtonText: this.$t('videochat_public_confirm_nickname_accept_button')
        }).then((result) => {
          if (result.isConfirmed) {
            this.createUser();
          }
        })
      },
      async createUser(){
        const activityId = isNaN(this.$route.params.id) ? this.$route.params.id.split("-")[this.$route.params.id.split("-").length-1] : this.$route.params.id
        let random = this.randomString(10, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        let signupData = {
          email: this.nickname+random+"@wearetestersfake.com",
          password: this.password,
          name: this.nickname,
          surname1: '.',
          registrationToken:this.theme.registrationToken,
        }
        await store.dispatch(USER_AUTH_SIGNUP,{signupData})
        .then((data) => {
        })
        .catch((response) => {
          this.error = true
          this.errorMessage = this.$t('error_nickname_duplicated')
        })
        if(this.error) return
        await store.dispatch(USER_FETCH_PROFILE)
        await store.dispatch(COMMUNITY_VIDEOCHAT_ADD_USER_PUBLIC,{ activityId: activityId })
        .then((data) => {
          this.showLogin = false
          this.showVideoChat = true
        })
      },
      randomString(length, chars) {
          var result = '';
          for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
          return result;
      },
      fetchConditions() {
        store.dispatch( COMMUNITY_FETCH_CONDITIONS)
          .then( async ( data ) => {
            this.acceptance = data.acceptance
          } )
      },
    },
    async mounted(){
      if(this.isObserverRoute){
        this.showLogin = true
        return
      }
      if(this.isAuthenticated && this.isUser){
        await store.dispatch(USER_FETCH_PROFILE)
        .then((data) => {
          this.showLogin = false
          this.showVideoChat = true
        })
      }
      else if(this.$route.query.auth == undefined){
        this.showLogin = true
        this.fetchConditions()
      }
      if(this.$route.query.auth != undefined){
        this.showLogin = false
        this.authToken = this.$route.query.auth
        this.loginObserver()
      }
    }
  };
</script>
<style lang="scss">
.VideoChatPublicPage{
  .GradientBack{
    height: 100vh;
    margin-bottom: 30px;
  }
  .VideoChatPage{
    max-height: 100vh;
  }
}
</style>
<style scoped lang="scss">
.VideoChatPublicPage{
  &--Box{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
  }
  &--Form{
    border: 1px solid lightgray;
    padding: 10px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    label{
      font-size: 19px;
      margin-top: 20px;
    }
    button{
      margin-top: 20px;
    }
    p{
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      padding: 10px;
      margin-top: 20px;
    }
  }
  &--Acceptance{
    div{
      display: flex;
      flex-direction: row!important;
      align-items: baseline;
      input{
        position:relative;
        margin-right: 10px;
        outline: none;
      }
      input::before{
        height:15px;
        width: 15px;
        border:1px solid var(--primary-color);
        color: transparent;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content:'\f00c';
        position: absolute;
        background: white;
        cursor:pointer;
      }
      input:checked::before{
        color: var(--primary-color);
      }
    }
  }
}
</style>
